body {
  margin: 0;
  padding: 0;
  font-family: arial;
}

.font-montserrat {
	font-family: 'Montserrat', sans-serif;
}


/* Color Pallete */
.color-white {
	color: #ffffff;
}

.color-yellow {
	color: #ffcc33;
}

.color-grey {
	color: #999999;
}

.color-dark-grey {
	color: #666666;
}

.color-charcoal {
	color: #373735;
}

.bg-color-yellow {
	background-color: #ffcc33;
}

.bg-color-grey {
	background-color: #999999;
}

.bg-color-dark-grey {
	background-color: #666666;
}

.bg-color-charcoal {
	background-color: #373735;
}

a.email-link {
	display: block;
	margin-top: 10px;
	text-decoration: none;
	font-weight: bold;
	color: #000;
	font-family: 'Montserrat', sans-serif;
}
