.react-tabs__tab-list {
	border: 0px;
}

.react-tabs__tab {
	background-color: #373735;
	color: #ffffff;
	border: 0px;
	border-radius: 0px;
}

.react-tabs__tab--selected {
	background-color: #ffcc33;
	color: #373735;
	border: 0px;
	border-radius: 0px;
	font-weight: bold;
}

.daytab .react-tabs__tab--selected:after {
	content: "";
	position: absolute;
	bottom: -9px;
	left: 30px;
	border-width: 10px 10px 0;
	border-style: solid;
	border-color: #ffcc33 transparent;
	display: block;
	width: 0;
}
