.app-wrapper .logo-link {
	padding: 0px;
	height: 100px;
}

.app-wrapper .logo-img {
	height: 60px;
	margin-top: 20px;
}

.nav > li > a {
	padding: 5px 20px;
}

.navbar-default .navbar-nav > li > a {
	color: #666666;
	padding: 5px 10px;
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
	color: #373735;
	background-color: #ffcc33;
	font-weight: bold;
}

.nav-collapse a div {
	font-size: 1.5em;
	padding: 15px;
}

@media (min-width: 768px) {
	.nav-collapse {
		padding-right: 15px;
		margin-top: 70px;
	}

	.nav-collapse a div {
		font-size: 1em;
		padding: 0px;
	}

	.navbar-nav > .active > a:after {
		content: "";
		position: absolute;
		bottom: -9px;
		left: 10px;
		border-width: 10px 10px 0;
		border-style: solid;
		border-color: #ffcc33 transparent;
		display: block;
		width: 0;
		z-index: 1000;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.app-wrapper .logo-img {
		height: 70px;
		margin-top: 15px;
	}
}

@media (min-width: 992px) {
	.app-wrapper .logo-img {
		height: 80px;
		margin-top: 10px;
	}
}
