.short-description .player-wrapper {
	display: block;
	margin-bottom: 20px;
	height: 200px;
}

@media (min-width: 768px) {
	.short-description .player-wrapper {
		float: right;
		margin: 0px 0px 10px 20px;
		width: 49%;
		height: 300px;
	}
}
