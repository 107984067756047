.footer-pcc-logo-wrapper {
	text-align: center;
}

.footer-message-wrapper {
	text-align: center;
	margin-top: 20px;
}

@media (min-width: 992px) {
	.footer-pcc-logo-wrapper {
		text-align: right;
		border-right: 5px dotted #373735;
	}

	.footer-message-wrapper {
		text-align: left;
		margin-top: 0px;
	}
}
