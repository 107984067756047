.under-banner-row-partner-message {
	margin-top: 5px;
}

.under-banner-row-partner-img {
	height: 35px;
	margin: 0px 12px 10px
}

@media (min-width: 992px) {
	.under-banner-row-partner-message {
		display: inline-block;
	}

	.under-banner-row-partner-img {
		height: 50px;
		margin: 15px 12px;
	}
}
