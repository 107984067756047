.headshots-box {
	max-width: 200px;
	margin: 0px auto;
	overflow: auto;
	border-radius: 0px 0px 20px 20px;
}

.headshots-category {
	font-weight: bold;
	font-size: 1.8em;
}

.headshots-name {
	font-size: 1em;
}

.headshots-position {
	font-size: .8em;
}

.headshots-company {
	font-size: .8em;
	font-weight: bold;
}

@media (min-width: 768px) {
	.headshots-category {
		font-size: 2.2em;
	}

	.headshots-name {
		font-size: 1.2em;
	}

	.headshots-position {
		font-size: .9em;
	}

	.headshots-company {
		font-size: .9em;
	}
}

@media (min-width: 1024px) {
	.headshots-box {
		height: 270px;
	}
} 

@media (min-width: 1200px) {
	.headshots-container .filter-grey {
		filter: grayscale(100%);
	}

	.headshots-container .filter-grey:hover {
		filter: grayscale(5%);
	}
}
