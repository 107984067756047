.banner-container {
	position: relative;
	height: 300px;
	background-position: 45% bottom;
	font-weight: bold;
}

.banner-container .logo-white {
	display: none;
}

.banner-message1 {
	position: absolute;
	left: 4%;
	top: 30px;
	font-size: 1.8em;
}

.banner-message2 {
	position: absolute;
	left: 4%;
	top: 70px;
	font-size: 1.8em
}

.banner-message3 {
	position: absolute;
	left: 4%;
	bottom: 70px;
	color: #fff;
	font-size: 1.1em
}

.banner-message4 {
	position: absolute;
	left: 4%;
	bottom: 30px;
	color: #fff;
	font-size: 1.1em
}

.banner-message5 {
	position: absolute;
	left: 4%;
	top: 110px;
	font-size: 1.8em
}

@media (min-width: 768px) {
	.banner-container {
		height: 400px;
	}

	.banner-container .logo-white {
		display: block;
		position: absolute;
		right: 10px;
		bottom: 10px;
		height: 45px;
	}

	.banner-message1 {
		font-size: 3em;
	}

	.banner-message2 {
		font-size: 3em;
		top: 80px;
	}

	.banner-message3 {
		font-size: 2.2em
	}

	.banner-message4 {
		font-size: 2.2em
	}

	.banner-message5 {
		font-size: 3em;
		top: 130px;
	}
}
